<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="项目订购批次记录"
      @ok="handleSubmit"
    >
      <div class="custom-red">在此可减少客户采购项目的数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。</div>
      <div class="custom-flex custom-justify-between set-background-box">
        <div>
          <div style="font-weight: bolder">已选项目</div>
          <div style="margin-top: 10px">共{{ itemCount }}项</div>
        </div>
        <div class="custom-flex" style="background-color: #ff9e1c">
          <a-icon type="pay-circle" style="height: 35px;width: 35px;color: #ffffff;margin: 3px;fontSize:45px" />
          <div style="color:#ffffff;padding-left: 10px;">
            项目金额：
            <div class="set-price-font">{{ totalFee | formatCurrency }}元</div>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="editingRecords"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350,x:1000 }"
        row-key="id"
      >
        <template slot="images" slot-scope="imgs, record">
          <!--          <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
          <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
        </template>
        <div slot="count" slot-scope="count, record" class="custom-flex count-edit-box">
          <a-input-number :value="record.back_count" :min="0" @change="(value) => changeBackCount(value, record)" />
          <span style="float: right;padding-top: 5px;">个</span>
          <!--          <a-icon type="plus-circle" theme="filled" style="padding-top: 5px;font-size: 20px;padding-left: 10px;color: #199cd5;" @click="plus(record)" />-->
          <!--          <a-icon type="minus-circle" theme="filled" style="padding-top: 5px;font-size: 20px;padding-left: 10px;color: #199cd5;" @click="minus(record)" />-->
        </div>
        <div slot="remark" slot-scope="remark, record" class="custom-flex count-edit-box">
          <a-input :value="record.remark" :id="record.id" @change="change" :maxlength="20" autocomplete="off"/>
        </div>
      </a-table>
      <!-- 预览图片 -->
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />

    </a-modal>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/filter'
import PreviewImage from '@/components/PreviewImage'
import { message } from 'ant-design-vue'
import { updateOtherItem, findConfirmSummary } from '@/api/order'

export default {
  name: 'ItemOrderBatchRecord',
  components: {
    PreviewImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      loading: false,
      previewVisible: false,
      previewImages: [],
      editingRecords: [],
      tempRecord: [],
      query: {},
      itemCount: 0,
      totalFee: 0,
      defaultTotalFee: 0,
      defaultItemCount: 0
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '品名',
          width: 100,
          dataIndex: 'product_name'
          // ellipsis: true
        },
        {
          title: '规格',
          width: 90,
          dataIndex: 'product_specification_name'
          // ellipsis: true
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          ellipsis: true,
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '价格',
          width: 120,
          dataIndex: 'price_display',
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '数量',
          width: 60,
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '小计(元)',
          width: 100,
          dataIndex: 'subscribe_fee_display',
          ellipsis: true
        },
        {
          title: '退货数量',
          width: 150,
          dataIndex: 'back_count',
          scopedSlots: { customRender: 'count' },
          ellipsis: true
        },
        {
          title: '订单号',
          width: 180,
          dataIndex: 'project_no',
          ellipsis: true
        },
        {
          title: '实收金额(元)',
          width: 180,
          dataIndex: 'fee',
          customRender: formatCurrency,
          ellipsis: true
        },
        {
          title: '订购时间',
          width: 200,
          dataIndex: 'created_at',
          ellipsis: true
        },
        {
          title: '备注',
          width: 200,
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' },
          ellipsis: true
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    changeBackCount(val, record) {
      this.tempRecord.forEach((item, index) => {
        if (item.id === record.id) {
          if (val > item.count) {
            message.error('数量不能大于原数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。')
          } else {
            record.back_count = val
            if (record.give === false) {
              record.fee = record.price * (record.count - record.back_count)
              this.totalFee = this.defaultTotalFee - item.fee + record.fee
            }
          }
        }
      })
    },
    change(e) {
      this.editingRecords.forEach((item, index) => {
        if (item.id === parseInt(e.target.id)) {
          if (e.target.value.length > 20) {
            this.editingRecords[index].remark = e.target.value.substring(0, 20)
          }
        }
      })
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },
    fetchData() {
      findConfirmSummary(this.id, this.query).then((res) => {
        this.editingRecords = res.data.data
        this.itemCount = res.data.item_count
        this.totalFee = res.data.total_fee
        this.defaultTotalFee = this.totalFee
        this.defaultItemCount = this.itemCount
        this.tempRecord = JSON.parse(JSON.stringify(this.editingRecords))
      })
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    plus(record) {
      this.tempRecord.forEach((item, index) => {
        if (item.id === record.id) {
          if ((record.back_count + 1) > item.count) {
            message.error('数量不能大于原数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。')
          } else {
            record.back_count++
            record.fee = record.price * (record.count - record.back_count)
            this.totalFee = this.defaultTotalFee - item.fee + record.fee
          }
        }
      })
    },

    minus(record) {
      if (record.back_count > 0) {
        record.back_count--
        record.fee = record.price * (record.count - record.back_count)
        this.tempRecord.forEach((item, index) => {
          if (item.id === record.id) {
            this.totalFee = this.defaultTotalFee - item.fee + record.fee
          }
        })
      }
    },
    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      const editData = Object.assign({}, { items: this.editingRecords })
      updateOtherItem(this.serviceOrderId, editData).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-edit-box {
  user-select: none;
}
</style>
